<template>
  <!-- <v-row>
      <v-col cols="6" style="padding-left: 50px; padding-right: 20px; padding-bottom: 20px;"> -->
  <div style="width: 100%">
    <v-dialog v-model="dialog" width="600px" height="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          fab
          small
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ type }}</span>
        </v-card-title>
        <vue-dropzone
          id="dropzone"
          ref="myVueDropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="afterUploadComplete"
          @vdropzone-error="handleUploadError"
        ></vue-dropzone>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="type !== 'doc_condition'" dense>
      <v-col
        v-for="(img, id) in dataImg"
        :key="id"
        cols="12"
        md="6"
        lg="3"
        xl="4"
        class="d-flex child-flex"
      >
        <v-card style="margin-top: 20px;">
          <v-img
            height="125"
            class="bg-grey-lighten-2"
            :src="img.signedUrl"
          ></v-img>
          <v-card-title class="text-h6">
            {{ img.blobMeta.title }}
            &ensp;
            <v-icon @click="removeImage(img)">mdi-close-thick</v-icon>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row style="padding-top: 20px;" dense v-else>
      <div>
        No img
      </div>
    </v-row> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from 'axios'

export default {
  dialog: false,
  components: {
    // Uploader,
    // data: [],
    vueDropzone: vue2Dropzone
  },
  props: {
    temporaryId: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    dataImg:[],
    dropzoneOptions: {
      url: '',
      thumbnailWidth: 150,
      maxFilesize: 1,
      headers: {},
      uploadMultiple: false,
      maxFiles: 15
    }
  }),
  computed: {
  },
  watch: {
    dialog: {
      handler(val) {
        if (val === false) this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    temporaryId: {
      handler(val) {
        this.fetchLeadPublicImg({
          id: val,
          type: this.type
        })
          .then((res) => {
            this.dataImg = res.data.response.signed_list
            // this.setImageList(this.dataImg)
            console.log(
              '[afterRemoveComplete][fetchCaseCustomerImage]:',res)
          })
        this.dropzoneOptions.url = axios.defaults.baseURL + `api/leads/upload_public_pic/?id=${val}&type=${this.type}`
      }
    }
  },
  created() {
    // this.dropzoneOptions.params.sell_id = this.temporaryId
    this.dropzoneOptions.url = axios.defaults.baseURL + `api/leads/upload_public_pic/?id=${this.temporaryId}&type=${this.type}`
    // console.log('this.dropzoneOptions.url : ', this.dropzoneOptions.url)
  },
  mounted() {
    if (this.temporaryId !== '') {
      this.dropzoneOptions.url = axios.defaults.baseURL + `api/leads/upload_public_pic/?id=${this.temporaryId}&type=${this.type}`
      this.fetchLeadPublicImg({
        id: this.temporaryId,
        type: this.type
      })
        .then((res) => {
          this.dataImg = res.data.response.signed_list
          // this.setImageList(this.dataImg)
          console.log(
            '[afterRemoveComplete][fetchCaseCustomerImage]:',res)
        })
    }
    // this.dropzoneOptions.url = axios.defaults.baseURL + `api/car/upload_car_sell_pic?id=${this.temporaryId}&type=${this.type}`
  },
  methods: {
    ...mapActions('agent', [
      'fetchLeadPublicImg', 
      'removeLeadImage']),
    handleUploadError(file, message, xhr) {
      console.log('handleUploadError', file, message, xhr)
    },
    afterUploadComplete(resp) {
      console.log('afterUploadComplete:', resp)
      if (resp.status === 'success') {
        setTimeout(() => {
          this.dialog = false
          this.fetchLeadPublicImg({
            id: this.temporaryId,
            type: this.type
          })
            .then((res) => {
              this.dataImg = res.data.response.signed_list
              // this.setImageList(this.dataImg)
              console.log(
                '[afterUploadComplete][fetchCarCustomerImage]:',res)
            })
          
        }, 1000)
      }
    },
    removeImage(imageObj) {
      this.removeLeadImage({
        id: this.temporaryId,
        blob: imageObj.resource
      }).then(() =>
        this.fetchLeadPublicImg({
          id: this.temporaryId,
          type: this.type
        })
          .then((res) => {
            this.dataImg = res.data.response.signed_list
            // this.setImageList(this.dataImg)
            console.log(
              '[afterRemoveComplete][fetchCaseCustomerImage]:',res)
          })
      )
    }
  }
}
</script>
<style>
.dropzone {
  min-width: 200px;
  /* height: 200px; */
  min-height: 100px !important;
  height: 400px;
  overflow: auto;
  /* max-width: 200px;
max-height: 200px; */
}
</style>
